import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import { BiLogInCircle } from "react-icons/bi";

function Reviews() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
      <BaseLayout PageName="Reviews">
        <div className="md:max-w-full w-full">
          <TransparentHeader
            headertitle="Reviews"
            Subheader="Reviews"
            bgimg={`${rpdata?.stock?.[2]}`}
            // para cambiar el svg del header busquemos en la carpeta css y buscamos el archivo de svgStyles
            // los estilo de svg para el header empiezan svgTranspareHeader seguido de un numero
            svgClass="svgTranspareHeader2"
          />   
        </div>

        <div className="w-4/5 mx-auto py-[100px]">
          <h1 className="text-center pb-10">
            {rpdata?.labels?.general?.titleReviews}
          </h1>
          {
          // activar tab encuentranos
          rpdata?.simpleWidgets?.[4]?.activo ?
            <>
              <div className="w-full flex md:flex-col justify-between self-center items-center my-4">

                  <div className="flex flex-col md:flex-row justify-center items-center md:justify-between w-full">
                    <span className="text-[30px] font-bold mx-3 text-center color-2">
                      Leave Us a Review!
                    </span>
                    <a 
                      href={rpdata?.gmb?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-footer text--slate-900 duration-300 ease-in-out hover:text-white  rounded-full px-5 py-2 w-[250px] flex items-center justify-center"
                      
                    >
                      <span className="px-2 text-[20px] font-bold text-white">
                        Write A Review
                      </span>
                      <BiLogInCircle className="text-[22px] font-bold text-white" />
                    </a>
                  </div>

              </div>
            </>
            : null
        }
          <review-widget widgetId={rpdata?.reviews?.links?.[0]}></review-widget>
        </div>

      </BaseLayout>
    );
}

export default Reviews;
